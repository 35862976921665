import React from 'react'

const AdvertiseCard = ({props}) => {
 const word = props.selectedOptiontype;
const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
    return (
      <div className="bg-white  rounded-lg shadow-lg p-6 w-full md:w-4/6  h-auto mx-auto my-4 flex flex-row justify-between cursor-pointer">
        <div className='flex flex-col w-auto '>
            <p><span className='text-black-900 text-2xl font-semibold'>{capitalizeFirstLetter(props.companyName)}</span> &nbsp;<span className='text-gray-400 font-light text-lg'>is hiring</span></p>
            <p className='text-red-600 text-3xl font-bold'>{props.title}</p>
            <p className='flex flex-row justify-between'><p className='font-thin'>{capitalizedWord}</p><p className='font-thin'>{props.selectedSalary}</p></p>
        </div>
        <div className='flex flex-col justify-center w-auto'>
            <div className='flex flex-row'>
            <p className='font-bold text-xs'>{props.city}</p>
            <p className='font-bold text-xs mx-1'>.</p>
            <p className='font-bold text-xs'>{props.selectedCountry}</p>
            </div>
        </div>
      </div>
    );
  };
  

export default AdvertiseCard