import React,{useEffect, useState} from 'react'
import Footers from '../Components/Footers';
import BannerHome from '../Components/BannerHome';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';
import logo from '../images/logo.png';
import { country } from '../values/constants';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Preview from '../Components/Preview';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useJobStore from '../GlobalState/Jobstore';
import { message } from 'antd';
import {addJobsAsync} from '../GlobalState/job-slice'
import { useSelector, useDispatch } from 'react-redux';
import { initializePaddle, Paddle } from '@paddle/paddle-js';

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

const PostJob = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSalary, setSelectedSalary] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedOptiontype, setSelectedOptiontype] = useState('fulltime');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedOptionduration, setSelectedOptionduration] = useState('1month');
    const [value, setValue] = useState('');
    const [title, settitle] = useState('');
    const [applylink, setapplylink] = useState('');
    const [city, setCity] = useState('');
    const [companyName, setcompanyName] = useState('');
    const [posterEmail, setposterEmail] = useState('');
    const [companyLink, setcompanyLink] = useState('');
    const [display_url, setDisplayUrl] = useState('');
    const [preview, setpreview] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [price, setprice] = useState(50);
    const [expirydate, setexpiryDate] = useState('');
    const [finalData, setFinalData] = useState({});
    const [paddle, setPaddle] = useState();
    const navigate = useNavigate();
    const priceList = [15,50,70];
    const notify = () => toast.error("Fill All Important Fields !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

        const notifySuccess = () => toast.success("Data Sent !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
            const dispatch = useDispatch();

            
    const category = [
        { value: 'UI/UX Design', label: 'UI/UX Design' },
        { value: 'UI Design', label: 'UI Design' },
        { value: 'UX Design', label: 'UX Design' },
        { value: 'Product Design', label: 'Product Design' },
        { value: 'UX Research', label: 'UX Research' },
        { value: 'UX Writing', label: 'UX Writing' },
      ];

      const  salary = [
        { value: '$0 - $30K', label: '$0 - $30K USD per year' },
        { value: '$30K - $60K', label: '$30K - $60K USD per year' },
        { value: '$60K - $90K', label: '$60K - $90K USD per year' },
        { value: '$90K - $120K', label: '$90K - $120K USD per year' },
        { value: '$120K - $150K', label: '$120K - $150K USD per year' },
        { value: '$150K - $180K', label: '$150K - $180K USD per year' },
        { value: '$180K - $210K', label: '$180K - $210K USD per year' },
        { value: 'over $210K', label: 'Over $210K USD per year' },
      ];

     // const navigate = useNavigate();
     const getExpiryDate  = (userSelection)=>{
      const currentTime = new Date();

      let futureTime;
      
      if (userSelection === '1week') {
       // futureTime = new Date(currentTime.getTime() + 7 * 24 * 60 * 60 * 1000); // Adding 1 week in milliseconds
        futureTime = new Date(currentTime.getTime() +   2*60 * 1000); // Adding 1 week in milliseconds
      } else if (userSelection === '1month') {
        const nextMonth = currentTime.getMonth() + 1;
        futureTime = new Date(currentTime.getFullYear(), nextMonth, currentTime.getDate());
      } else if (userSelection === '1.5month') {
        futureTime = new Date(currentTime.getTime() + 45 * 24 * 60 * 60 * 1000); // Adding 45 days in milliseconds
      }
      
      const formattedTime = futureTime.toISOString().replace('T',' ').replace('Z',''); // Convert to UTC format suitable for PostgreSQL timestamp
      setexpiryDate(formattedTime);
    }

     useEffect(()=>{
      getExpiryDate(selectedOptionduration);
     },[selectedOptionduration]);

      const addJob = useJobStore((state) => state.addJob);


      // const getIp = async()=>{
      //   const res = await fetch('https://api64.ipify.org');
      //   console.log(res);
      //   getLocation(res);
      // }

      // const getLocation = async(ip)=>{
      //   const res = await fetch(`http://www.geoplugin.net/php.gp?ip=${ip}`);
      //   const data = await res.json();
      //   console.log(data);
      // }

      async function displayRazorpay () {

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
    
          if (!res){
            alert('Razropay failed to load!!')
            return 
          }
    
          const data = await fetch('http://178.16.138.73:6700/order-pay', {method: 'POST'}).then((t) => 
            t.json()
          ) 
    
         // console.log('datadaatatd pay',data)
    
        const options = {
          "key": "rzp_test_1zVfNQPqqM4qIr", // Enter the Key ID generated from the Dashboard
          "amount": "5", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          "currency": "USD",
          "name": "UI/UX JOBS HUB",
          "description": "Test Transaction",
          "image": "https://i.ibb.co/SPHr5qz/UX-Jobs-Hub.png",
          "order_id": data.clientSecret, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          "notes": {
              "address": "Razorpay Corporate Office"
          },
          "theme": {
              "color": "#3399cc"
          },
          "handler": function (response){
          //  console.log('response from handler', response);
            finalPay();
          }
      };
      const paymentObject = new window.Razorpay(options); 
      paymentObject.open();
      }

      const handlePostJob = async (e) => {
        e.preventDefault();
        if(isValid===false){
         // notify();
         message.error('Fill All Important Fields !')
        //  console.log('insode notify postjob')
          return;
      }else{
         // notifySuccess();
        //  console.log('insode postjob success');
          //navigate('/payments');
          const AggregatedData = {
            selectedcategory: selectedCategory.value,
            selectedsalary: selectedSalary.value,
            selectedcountry: selectedCountry.value,
            selectedoptiontype: selectedOptiontype,
            selectedoptionduration: selectedOptionduration,
            value: value,
            title: title,
            applylink: applylink,
            city: city,
            companyname: companyName,
            posteremail: posterEmail,
            companywebsitelink: companyLink,
            display_url: display_url,
            price: price,
            expirydate:expirydate,
            user_email:posterEmail
          };
          setFinalData(AggregatedData);
        
        //  await displayRazorpay();
      await openCheckout();
          
      }
        
        
      }

       // Download and initialize Paddle instance from CDN
  useEffect(() => {
    initializePaddle({ environment: 'sandbox', token: 'test_7fca022df3f364a824be8dc70c4',
      eventCallback: async (data) =>  {
        if (data.name === "checkout.completed"&& data.data.status==='completed') {
        //  console.log("checkout.completed",data);
          await finalPay();
        }
      } 
    }).then(
      (paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      },
    );
  }, [finalData]);
function getPriceId(price) {
  switch (price) {
    case 15:
      return 'pri_01j4yaxev93hjk6jswmtx3qpsa';
    case 50:    
      return 'pri_01j4yaya28y9q39zm7qd8d2eaa';
    case 70:
      return 'pri_01j4yb4abgx4cb4q7xsw09v24a';
    default:
      return 'pri_01j4yaxev93hjk6jswmtx3qpsa';
}
}
  // Callback to open a checkout
  const openCheckout = () => {
    const truePriceId = getPriceId(price);
    paddle?.Checkout.open({
      items: [{ priceId: `${truePriceId}`, quantity: 1 }],
    });
  };
      const finalPay = async () => {
        const AggregatedData = {
          selectedcategory: selectedCategory?.value||finalData?.selectedcategory,
          selectedsalary: selectedSalary?.value||finalData?.selectedsalary,
          selectedcountry: selectedCountry?.value||finalData?.selectedcountry,
          selectedoptiontype: selectedOptiontype||finalData?.selectedoptiontype,
          selectedoptionduration: selectedOptionduration||finalData?.selectedoptionduration,
          value: value||finalData?.value,
          title: title||finalData?.title,
          applylink: applylink||finalData?.applylink,
          city: city||finalData?.city,
          companyname: companyName||finalData?.companyname,
          posteremail: posterEmail||finalData?.posteremail,
          companywebsitelink: companyLink||finalData?.companywebsitelink,
          display_url: display_url||finalData?.display_url,
          price: price||finalData?.price,
          expirydate:expirydate||finalData?.expirydate,
          user_email:posterEmail||finalData?.user_email
        };
      //  console.log('data==>', AggregatedData, finalData);
        const response = await dispatch(addJobsAsync(JSON.stringify({
          data: AggregatedData,
        })));
      //  console.log("dcedce",response);
        if(response.payload.data.success){
          paddle?.Checkout.close();
          message.success(response.payload.data.message);
          navigate('/');
        }else{
          paddle?.Checkout.close();
          message.error('Something went wrong');
        }
      }
      
      // const checkout = (Incomingdata) => {
      //   fetch("https://serverless-api.shubhankardev8.workers.dev/v1/api/register-job", {
      //     method:"POST",
      //     headers:{
      //       "Content-Type":"application/json"
      //     },
          
      //     body: JSON.stringify({
      //       data: Incomingdata,
      //     })
      //   })
      //   .then(res => {
      //     if (res.ok) return res.json()
      //   })
      //   .catch(e => {
      //     console.log(e.error)
      //   })
      // }

      const handlePreviewClick = () =>{
        if(isValid===false){
            //notify();
            message.error('Fill All Important Fields !')
          //  console.log('insode notify')
        }else{
            setpreview(!preview)
          //  console.log('insode preview')
        }
        
      }

      const handleChange = (event) => {
        setSelectedOptiontype(event.target.value);
      };

      

      const handleFileChange = (event) => {
        const file = event.target.files[0];
      //  console.log(file);
        setSelectedFile(file);
      };

      

      const handleOptionChange = (event) => {
        setSelectedOptionduration(event.target.value);
        if(event.target.value==='1week'){
          setprice(priceList[0]);
        }else if(event.target.value==='1month'){
          setprice(priceList[1]);
        }else{
          setprice(priceList[2]);
        }
        
      };
      useEffect(() => {
        if (selectedFile!==null) {
          uploadImageData();
        }
      }, [selectedFile]);
      

      useEffect(()=>{
        
        window.scrollTo(0, 0);
      //  getIp();
      },[]);

      useEffect(()=>{
        if(selectedCategory!==null&&selectedSalary!==null&&selectedCountry!==null&&value!==''&&title!==''&&applylink!==''&&city!==''&&companyName!==''&&companyLink!==''
         // &&display_url!==''
        ){
            setIsValid(true)
        }else{
            setIsValid(false)
        }
      },[selectedCategory,selectedSalary,selectedCountry,value,title,applylink,city,companyName,posterEmail,companyLink,display_url]);


      const uploadImageData = () => {
        if (!selectedFile) {
          //  console.log('No file selected.');
            return;
          }
          
        const form = new FormData();
      //  console.log('form', selectedFile.slice(22));
        form.append('image', selectedFile);
        form.set('key', 'afc4ea18db790b9f3ab9406360857bd1');
        axios
          .post('https://api.imgbb.com/1/upload', form, {
            'Content-Type': 'multipart/form-data',
          })
          .then((res) => {
          //  console.log('ddcd', res);
            setDisplayUrl(res.data.data.display_url);
          })
    
          .catch((err) => {
            console.log(err);
          });
      };

      

      
  return (
    <div>
       <div className=' h-auto'>
            {/* banner is done */}
                <div className='bg-white h-fit w-100 flex justify-around  flex-col'>
                <div className='flex flex-row justify-start lg:w-9/12 h-fit  lg:text-5xl text-2xl font-bold lg:mx-auto mt-5'>
                <Link to ="/"><img src={logo} className='w-28' /></Link>
                    <ul className='text-sm flex flex-row text-red-500 font-mono'>
                        <Link to ="/terms"><li className='mx-3'>Terms</li></Link>
                        <Link to ="/privacy-policy"><li className='mx-3'>Privacy</li></Link>
                        <Link to ="/contact-us"><li className='mx-3'>Get in touch</li></Link>
                        {/* <a href={`mailto:${'shubhankars361@gmail.com'}?subject=${encodeURIComponent('Issue')}`}></a> */}
                    </ul>
                    </div>
                    {/* <BannerHome/> */}
                    <div className='flex flex-wrap lg:w-9/12 h-fit  lg:text-5xl text-2xl font-bold mt-20 mx-auto'>
                    Discover Top Design Talent: Find Exceptional UI Designers and UX Researchers. Start Your Search Today!
                    </div>
                </div>

                { preview &&(<div>
               <div className='flex md:flex-row flex-col  justify-between h-auto w-full  lg:w-7/12 mx-auto bg-slate-100  font-mono my-14'>
                <div className='flex flex-col justify-around font-bold text-2xl '>Job Details</div>
                <div className='flex flex-col md:w-3/5 mx-4 md:mx-0 w-full'>
                    <div className='flex flex-col my-3'>
                        <label for="job-title" className="font-bold text-base ">Title<span className="text-red-500">*</span></label>
                        <input id="job-title" type="text"  placeholder="Enter value" className='border border-gray-300 rounded-lg py-2 px-4 block w-3/4 leading-6 focus:outline-none' value={title} onChange={(e)=>settitle(e.target.value)} required/>
                    </div>
                    <div className='flex flex-col my-3'>
                        <label for="job-category" className="font-bold text-base ">Category<span className="text-red-500">*</span></label>
                        <Select id="job-category" className="border border-gray-300 rounded-lg  block w-3/4 leading-6 focus:outline-none"  defaultValue={selectedCategory} onChange={setSelectedCategory} options={category} required/>
                    </div>
                    <div className='flex flex-col my-3'>
                        <label for="job-category" className="font-bold text-base ">Type</label>
                        <div className="flex space-x-4">
                                    <div>
                                        <input
                                        type="radio"
                                        id="fulltime"
                                        name="jobType"
                                        value="fulltime"
                                        checked={selectedOptiontype === 'fulltime'}
                                        onChange={handleChange}
                                        />
                                        <label htmlFor="fulltime">Full-time</label>
                                    </div>
                                    <div>
                                        <input
                                        type="radio"
                                        id="contract"
                                        name="jobType"
                                        value="contract"
                                        checked={selectedOptiontype === 'contract'}
                                        onChange={handleChange}
                                        />
                                        <label htmlFor="contract">Contract</label>
                                    </div>
                                    <div>
                                        <input
                                        type="radio"
                                        id="internship"
                                        name="jobType"
                                        value="internship"
                                        checked={selectedOptiontype === 'internship'}
                                        onChange={handleChange}
                                        />
                                        <label htmlFor="internship">Internship</label>
                                    </div>
                                    </div>
                    </div>
                    <div className='flex flex-col my-3'>
                        <label for="job-category" className="font-bold text-base ">Salary(optional)</label>
                        <Select id="job-salary" className="border border-gray-300 rounded-lg  block w-3/4 leading-6 focus:outline-none"  defaultValue={selectedSalary} onChange={setSelectedSalary} options={salary} />
                        
                    </div>
                    <div className='flex flex-col my-3' >
                        <label for="job-category" className="font-bold text-base ">How To Apply<span className="text-red-500">*</span></label>
                        <input id="job-apply-link" type="text" value={applylink} onChange={(e)=>setapplylink(e.target.value)} placeholder="Enter value" className="border border-gray-300 rounded-lg py-2 px-4 block w-3/4 leading-6 focus:outline-none" required={true}/>
                    </div>
                </div>
               </div>
               {/* its time for the form section 1 */}


                {/* its time for the form section 2 */}
               <div className='flex md:flex-row flex-col  justify-between h-auto w-full  lg:w-7/12 mx-auto bg-slate-100 font-mono my-14'>
                <div className='flex flex-col justify-around text-2xl font-bold '>Job Location</div>
                <div className='flex flex-col md:w-3/5 mx-4 md:mx-0 w-full'>
                    <div className='flex flex-col my-3'>
                        <label for="job-title" className="font-bold text-base ">Country<span className="text-red-500">*</span></label>
                        <Select id="job-salary" className="border border-gray-300 rounded-lg  block w-3/4 leading-6 focus:outline-none"  defaultValue={selectedCountry} onChange={setSelectedCountry} options={country} required={true}/>
                    </div>
                    <div className='flex flex-col my-3'>
                        <label for="job-category" className="font-bold text-base ">City<span className="text-red-500">*</span></label>
                        <input id="job-category" type='text' value={city} onChange={(e)=>setCity(e.target.value)} placeholder='Enter value' className="border border-gray-300 rounded-lg py-2 px-4 block w-3/4 leading-6 focus:outline-none" required={true}/>
                    </div>
                    
                </div>
               </div>
                {/* its time for the form section 2 */}


                

                {/* its time for the form section 3 */}

                <div className='flex md:flex-row flex-col  justify-between h-auto w-full  lg:w-7/12 mx-auto bg-slate-100 font-mono my-14'>
                <div className='flex flex-col justify-around text-2xl font-bold '>Company Details</div>
                <div className='flex flex-col md:w-3/5 mx-4 md:mx-0 w-full'>
                    <div className='flex flex-col my-3'>
                        <label for="job-title" className="font-bold text-base ">Name<span className="text-red-500">*</span></label>
                        <input id="job-title" type='text' value={companyName} onChange={(e)=>setcompanyName(e.target.value)} placeholder='Enter value' className="border border-gray-300 rounded-lg py-2 px-4 block w-3/4 leading-6 focus:outline-none" required={true}/>
                    </div>
                    <div className='flex flex-col my-3'>
                        <label for="job-category">Jobposter Email<span className="text-red-500">*</span></label>
                        <input id="job-category" type='text' value={posterEmail} onChange={(e)=>setposterEmail(e.target.value)} placeholder='Enter value' className="border border-gray-300 rounded-lg py-2 px-4 block w-3/4 leading-6 focus:outline-none" required={true}/>
                    </div>
                    <div className="flex flex-col my-3">
                    <label htmlFor="job-category" className="mb-2">
                                                Logo<span className="text-red-500">*</span>
                                            </label>
                                            <input
                                                type="file"
                                                id="job-category"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                                className="border border-gray-300 rounded-lg py-2 px-4 block w-3/4 leading-6 focus:outline-none"
                                                
                                            />
                    </div>
                    
                    <div className='flex flex-col my-3'>
                        <label for="job-category">CompanyURL <span className="text-red-500">*</span></label>
                        <input id="job-category" type='text' value={companyLink} onChange={(e)=>setcompanyLink(e.target.value)} placeholder='Enter value' className="border border-gray-300 rounded-lg py-2 px-4 block w-3/4 leading-6 focus:outline-none"/>
                    </div>
                </div>
               </div>

                {/* its time for the form section 3 */}


                {/* its time for the form section 3.5 */}
               <div className='flex md:flex-col flex-col  justify-between h-auto w-full  lg:w-7/12 mx-auto bg-slate-100 font-mono my-14'>
                <div className=' text-2xl font-bold '>Job Description<span className="text-red-500">*</span></div>
                <div className=' h-80 w-full overflow-y-auto bg-white'>
                <ReactQuill theme="snow" value={value} onChange={setValue} className='h-full overflow-y-scroll w-full' required={true}/>
                
                </div>
               </div>
                {/* its time for the form section 3.5 */}



                {/* its time for the form section 4 */}
                <div className='flex md:flex-row flex-col  justify-between h-auto w-full  lg:w-7/12 mx-auto bg-slate-100 font-mono my-14'>
                <div className='flex flex-col justify-around text-2xl font-bold '>Highlight Job</div>
                <div className="flex flex-col space-y-2 md:mx-auto mx-0 ml-10 my-2">
                    
                    
                    <div className="flex flex-col space-y-2">
                    <label>Post Job For:</label>
                    <div className="flex items-center">
                        <input
                        type="radio"
                        id="pin-1week"
                        value="1week"
                        checked={selectedOptionduration === '1week'}
                        onChange={handleOptionChange}
                        className="mr-2"
                        />
                        <label htmlFor="pin-1day">1 week</label>
                    </div>
                    <div className="flex items-center">
                        <input
                        type="radio"
                        id="pin-1month"
                        value="1month"
                        checked={selectedOptionduration === '1month'}
                        onChange={handleOptionChange}
                        className="mr-2"
                        />
                        <label htmlFor="pin-1week">1 month</label>
                    </div>
                    <div className="flex items-center">
                        <input
                        type="radio"
                        id="pin-1month"
                        value="1.5month"
                        checked={selectedOptionduration === '1.5month'}
                        onChange={handleOptionChange}
                        className="mr-2"
                        />
                        <label htmlFor="pin-1month">45 Days</label>
                    </div>
                    </div>
                    </div>
                </div> 

                {/* this is where we display our price */}
                   <div className='flex md:flex-row flex-col  justify-between h-auto w-full  lg:w-7/12 mx-auto bg-slate-100 font-mono my-14'>
                    <p><span  className=' text-2xl font-bold '>NetPrice:</span><span className=' text-2xl font-bold'>&nbsp;&nbsp;&nbsp;{price}$</span></p>
                    </div>
                </div>
                )}

                {/* its time for the form preview element start */}
              {!preview && (
                <Preview selectedCategory={selectedCategory.value}
                jobid={'nill'}
                selectedSalary={selectedSalary.value}
                selectedCountry={selectedCountry.value}
                selectedOptiontype={selectedOptiontype}
                selectedFile={selectedFile}
                selectedOptionduration={selectedOptionduration}
                value={value}
                title={title}
                applylink={applylink}
                city={city}
                companyName={companyName}
                posterEmail={posterEmail}
                companytwitterLink={companyLink}
                display_url={display_url} />
              )}


                {/* its time for the form preview element end */}


            </div>
                {/* its time for the form section 4 */}
                <div className="flex flex-row   justify-end h-auto w-full  lg:w-7/12 mx-auto  font-mono my-14">
                <button className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 mx-2 px-4 rounded-lg" onClick={()=>handlePreviewClick()} >
  {preview?'Preview Job':'Go Back'}
</button><ToastContainer />
                <button className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-lg" onClick={(e)=>handlePostJob(e)}>
  Post Your Job
</button>
<ToastContainer />

                </div>
            
            <Footers/>
        </div>
  )
}

export default PostJob;