import React,{useState,useEffect} from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import {QRCodeSVG} from 'qrcode.react';
import parse from 'html-react-parser';

import { Modal,Typography ,Divider} from 'antd';
const Preview = (props) => {
  const [userCountry, setUserCountry] = useState('');
  // const fetchUserCountry = async () => {
  //   try {
  //     const response = await fetch('https://geolocation-db.com/json/');
  //     const data = await response.json();
  //     console.log('data:===>', data);
  //     setUserCountry(data.country_name);
  //   } catch (error) {
  //     console.error('Error fetching user country:', error);
  //   }
  // };
  // console.log('props000000000000',props)
 const {Text} = Typography;
  const handleXTweet = () => {
    // Define the text and URL you want to include in the tweet
    const currentUrl = window.location.href;
    const tweetText = `🎉🎉Amazing Oppurtunity!!!🥳.
          ${props.title} at ${props.companyName}.
          Average salary is ${props.selectedSalary}.
          Apply here ${currentUrl}`;
          
    const tweetUrl = currentUrl;
    // 'http://localhost:3000/summary?id=1 ';

    // Encode the text and URL for use in the Twitter intent URL
    const encodedText = encodeURIComponent(tweetText);
    const encodedUrl = encodeURIComponent(tweetUrl);

    // Construct the Twitter intent URL &url=${encodedUrl}
    const twitterIntentUrl = `https://twitter.com/intent/tweet?text=${encodedText}`;

    // Open a new window with the Twitter intent URL
    window.open(twitterIntentUrl, '_blank');
}
const handleLinkedInShare = () => {
  // Define the URL of the article or content you want to share
  const currentUrl = window.location.href;
  const articleUrl = 'https://uiuxjobsboard.com/job/818224-ux-researcher-berlin-germany';

  // Encode the URL for use in the LinkedIn share URL
  const encodedUrl = encodeURIComponent(currentUrl);

  // Construct the LinkedIn share URL
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;

  // Open a new window with the LinkedIn share URL
  window.open(linkedInShareUrl, '_blank');
}
 const handleActionBasedonCountry = () => {
 // console.log('applylink',props.applylink);
  window.open(`${props.applylink}`, '_blank');
  // if(userCountry===props.selectedCountry||userCountry==='Not found'){
  //   window.open(`http://${props.companywebsiteLink}`, '_blank');
  // }else{
  //   // open modal
  //  Modal.error({
  //   title: 'Error',
  //   content: `This Job Post is not available in your country. \n Your country is ${userCountry}. Allowed country is ${props.selectedCountry}.`,
  //   okButtonProps: { style: { background: '#ff4d4f', borderColor: '#ff4d4f' } }
  //  })

  // }
  
 } 
  // useEffect(() => {
  //   fetchUserCountry();
  // },[])

  const modules = {
    toolbar: false,
    
    }

 // console.log('applylink',props.applylink);
 // console.log('company',props.companywebsiteLink);
    return (
      <div className="flex   md:flex-row flex-col  justify-between h-auto w-full  lg:w-full mx-auto    font-mono my-0 p-6 rounded-lg ">
         <div className="flex   md:flex-col flex-col border-2  justify-between h-auto w-full  lg:w-7/12 mx-auto   font-mono my-3 p-6 rounded-lg  "
           style={{backgroundPosition: 'top', background: 'linear-gradient(to bottom, #d9d9e3 0.5%, whitesmoke 10%,white 69%)'}}>
            {props.jobid==='nill'?<h2 className="text-4xl font-extrabold mb-4 nunito">Preview</h2>:''}
  
        {/* Basic Information md:w-7/12  */}
        <div className=" w-11/12 mb-6 flex md:flex-col flex-row justify-stretch ">
        <div className="mb-6 flex flex-wrap justify-between    ">
          
          <p className='font-extrabold text-4xl oswald  '>Title <p className='mb-4 mt-2 font-mono text-sm generalText'>⭐{props.title}</p></p>
          
          <p className='font-extrabold text-4xl oswald '>Category <p className='mb-4 mt-2 font-mono text-sm generalText'>🚀{props.selectedCategory}</p></p>
          
         
          {/* <p className='font-extrabold text-4xl oswald '>😃Type <p className='mb-4 mt-2 font-mono text-sm capitalize ml-10'>{props.selectedOptiontype}</p></p>
         
          <p className='font-extrabold text-4xl oswald '>💰Salary <p className='mb-4 mt-2 font-mono text-sm ml-10'>{props.selectedSalary}</p></p>
         
          <p className='font-extrabold text-4xl oswald '>🌍Job Location<p className='mb-4 mt-2 font-mono text-sm ml-10'>{props.selectedCountry},{props.city}</p></p> */}
          
        </div>
        <div className="mb-6 flex flex-wrap justify-start justify-between  ">
          
          {/* <p className='font-extrabold text-4xl oswald  '>⭐Title <p className='mb-4 mt-2 font-mono text-sm ml-10'>{props.title}</p></p>
          
          <p className='font-extrabold text-4xl oswald '>🚀Category <p className='mb-4 mt-2 font-mono text-sm ml-10'>{props.selectedCategory}</p></p> */}
          
          <p className='font-extrabold text-4xl oswald '>Type <p className='mb-4 mt-2 font-mono text-sm capitalize generalText'>😃{props.selectedOptiontype}</p></p>
         
          <p className='font-extrabold text-4xl oswald '>Salary <p className='mb-4 mt-2 font-mono text-sm generalText'>💰{props.selectedSalary}</p></p>
         
          <p className='font-extrabold text-4xl oswald '>Job Location<p className='mb-4 mt-2 font-mono text-sm generalText'>🌍{props.selectedCountry},{props.city}</p></p>
          
        </div>
        {/* hidden sm:block */}
         {/* <div className='bg-white p-2  rounded-xl md:w-2/5 nunito w-4/5 md:mx-0 mx-auto h-fit '>
           <div className=" flex flex-col shadow-md border-black border-solid border-2 rounded-xl w-full" style={{background: 'linear-gradient(to bottom, black 15%, whitesmoke 10%,white 69%)'}}> 
          
          <div className='w-28 h-28 mx-auto bg-white rounded-full overflow-hidden m-4'>
             <img className='w-full h-full mx-auto my-auto object-contain  scale-75' alt="comp-ima" src={props.display_url} style={{}} />
          </div>
          <p className='mb-4 mx-auto  font-extrabold text-4xl oswald ' style={{color: '#333333'}} onClick={() => window.open(`${props.companywebsiteLink}`, '_blank')}>{props.companyName}</p>
          <button class=" mb-4 font-extrabold hover:text-black hover:bg-white hover:border-black  mx-auto bg-black text-white border  border-white px-4 py-2 rounded-md transition duration-300 ease-in-out" 
          onClick={() =>{ handleActionBasedonCountry(); }}>
            Apply Now
         </button>
         <p className='mb-4 mx-auto font-extrabold text-base oswald '>Share this job:</p>
         <div className='bg-white p-2 shadow-2xl rounded-lg mx-auto mb-4'><QRCodeSVG value={props.applylink}  bgColor='white' height={80} width={80} /></div>
          
          </div>
        </div> */}

        </div>
        <div className="mb-6 flex flex-col ">
          <Divider/>
          <p className='font-extrabold text-4xl oswald '>Job Description</p>
          {/* <div className='mb-4 font-mono text-sm'>{parse(props.value)} </div> 💼 🏢 📧 */}
          <div className='mb-4 font-mono text-sm mt-1' style={{border:'none'}}>
            <ReactQuill value={props.value} readOnly={true} theme='bubble' modules={modules}  /> 
               {/* <pre style={{whiteSpace: 'pre-wrap'}}>{parse(props.value)}</pre>  */}
             </div>
          <p className='font-extrabold text-4xl oswald'>Company</p>
          <p className='mb-4 mt-2 font-mono text-sm font-bold generalText'>{props.companyName}</p>
          <p className='font-extrabold text-4xl oswald '>JobPoster Email</p>
          <p className='mb-4 mt-2 font-mono text-sm font-bold generalText'>{props.posterEmail}</p>
        </div>
  
    
        
      </div>
      {/* side */}
      <div className='bg-white  mt-3 rounded-xl md:w-2/6 nunito w-full md:mx-0 mx-auto h-fit' style={{position:'sticky', top: 50}}>
           <div className=" flex flex-col shadow-md   rounded-xl w-full" style={{background: 'linear-gradient(to bottom, black 18%, whitesmoke 10%,white 70%)'}}> 
          
          <div className='w-28 h-28 mx-auto bg-white rounded-full overflow-hidden m-4'>
             <img className='circular-image' alt="comp-ima" src={props.display_url} style={{}} />
          </div>
          <p className='mb-4 mx-auto  font-extrabold text-3xl  oswald ' style={{color: '#333333'}} >{props.title}</p>
          <p className='mb-4 mx-auto  font-extrabold text-xl oswald ' style={{color: '#333333'}} onClick={() => {window.open(`${props.companywebsiteLink}`, '_blank');}}>@{props.companyName}</p>
          <div className='flex flex-wrap justify-between mx-32 '>
          <div className='text-xs  w-fit my-3 '><p className='font-bold'>🧑‍💼CATEGORY</p><p style={{backgroundColor:'black', color:'white', padding:'5px', borderRadius:'20px',width:'fit-content'}} className='generalTextmini'>{props.selectedCategory}</p></div>
          <div className='text-xs  w-fit my-3 '><p className='font-bold'>🕴️TYPE</p><p className='capitalize generalTextmini' style={{backgroundColor:'black', color:'white', padding:'5px', borderRadius:'20px',width:'fit-content'}} >{props.selectedOptiontype}</p></div>
          </div>
          <div className='flex flex-wrap justify-between mx-32 '>
          <div className='text-xs  w-fit my-3'><p className='font-bold'>💸SALARY</p><p style={{backgroundColor:'black', color:'white', padding:'5px', borderRadius:'20px',width:'fit-content'}} className='generalTextmini'>{props.selectedSalary}</p></div>
          <div className='text-xs  w-fit my-3'><p className='font-bold'>📍LOCATION</p><p style={{backgroundColor:'black', color:'white', padding:'5px', borderRadius:'20px',width:'fit-content'}} className='generalTextmini'>{props.selectedCountry}</p></div>
          </div>
          <button class=" mb-4 font-extrabold hover:text-black hover:bg-white hover:border-black  mx-auto bg-black text-white border  border-white px-4 py-2 rounded-md transition duration-300 ease-in-out" 
          onClick={() => handleActionBasedonCountry()}>
            Apply Now 📨
         </button>
         
         <p className='mb-4 mx-auto font-extrabold text-base oswald '>SHARE THIS JOB:</p>
         <div className='bg-white p-2  rounded-lg mx-auto mb-4  w-full h-auto flex flex-row justify-evenly'>
         
         <div className='w-fit h-auto cursor-pointer mt-5' onClick={()=>handleXTweet()}><span><i class="fa-brands fa-x-twitter" style={{fontSize:'20px', color:'black'}}></i></span></div>

          {/* <div className='w-fit h-auto '><QRCodeSVG value={props.applylink}  bgColor='white' height={80} width={80} /></div> */}
          
          <div className='w-fit h-auto cursor-pointer mt-5' onClick={()=>handleLinkedInShare()}><span><i class="fab fa-linkedin-in" style={{fontSize:'20px', color:'black'}}></i></span></div>
          </div>
          <div className='bg-white p-2  rounded-lg mx-auto mb-4  w-full h-auto flex flex-col justify-around'>
         
          <p className='mx-2'><Text strong className='generalTextmini'>👋	Please mention that you come from <strong>UIUX/JOBS HUB</strong> when applying for this job.</Text></p>
          <p className='mx-2'><Text strong className='generalTextmini'>📍	Do not spam apply. Be aware of the location restriction for a job position</Text></p>
          <p className='mx-2'><Text strong className='generalTextmini'> 🛑	Beware of scams!!!. When applying for jobs, you should NEVER have to pay anything</Text></p>
          </div>
          </div>
        </div>
      {/* side */}
      </div>
    );
  };
  

export default Preview;