import React, {useEffect} from 'react'
import logo from '../images/logo.png';
import Footers from '../Components/Footers';
import { Link } from 'react-router-dom';

const Contact = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
  return (
    <div>
        <div className=' h-auto mb-36'>
            {/* banner is done */}
                <div className='bg-white h-fit w-100 flex justify-around  flex-col'>
                    <div className='flex flex-row justify-start lg:w-6/12 h-fit  lg:text-5xl text-2xl font-bold lg:mx-auto mt-24'>
                    <Link to="/"><img src={logo} className='w-28' alt='company-logo' /></Link>
                    <ul className='text-sm flex flex-row text-red-500 font-mono'>
                    <Link to ="/terms"><li className='mx-3'>Terms</li></Link>
                    <Link to ="/privacy-policy"><li className='mx-3'>Privacy</li></Link>
                    <Link to ="/contact-us"><li className='mx-3'>Get in touch</li></Link>
                    </ul>
                    </div>
                    {/* <BannerHome/> */}
                    <div className='flex flex-wrap lg:w-6/12 h-fit  lg:text-5xl text-2xl font-bold mt-20 mx-auto'>
                    Contact Us
                    </div>
                </div>

          
            
           


           <div className='flex font-mono flex-col flex-wrap md:w-6/12  p-10 pt-0 mt-4  mx-auto'>
           <form
                action="https://formsubmit.co/shubhankardev8@gmail.com"
                method="POST"
                id="contact-form"
                name="EmailForm"
                className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
              >
                <div className="mb-4">
                  <label
                    htmlFor="input-name"
                    className="block text-gray-700 text-xl font-bold mb-2"
                  >
                    Your Name
                  </label>
                  <input
                    id="input-name"
                    name="name"
                    type="text"
                    placeholder="Your Name"
                    required
                    className="appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="input-email"
                    className="block text-gray-700 text-xl font-bold mb-2"
                  >
                    Your Email
                  </label>
                  <input
                    id="input-email"
                    name="input-email"
                    type="email"
                    required
                    placeholder="Your Email"
                    className="appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="input-message"
                    className="block text-gray-700 text-xl font-bold mb-2"
                  >
                    Message
                  </label>
                  <textarea
                    id="input-message"
                    name="input-message"
                    rows="3"
                    placeholder="Message"
                    required
                    className="appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
                <div className="flex items-center justify-between">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    name="_next"
                    value="http://localhost:3000/contact-us"
                  >
                    SEND MESSAGE
                  </button>
                </div>
              </form>

           </div>
            

        </div>
        <Footers/>
    </div>
  )
}

export default Contact;