import React,{useEffect, useState} from 'react'
import AdvertiseCard from '../Components/AdvertiseCard';
import Footers from '../Components/Footers';
import BannerHome from '../Components/BannerHome';
import { JobCardData } from '../values/constants';
import { Link } from 'react-router-dom';
import {fetchAllJobsAsync} from '../GlobalState/job-slice';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Spin, Skeleton, Empty, Typography } from 'antd';
import { Helmet } from 'react-helmet-async';
import { Seo } from './Seo';

const HomePage = () => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const fetchAllJobs = async() => {
     const response =  await dispatch(fetchAllJobsAsync());
     setLoading(false);
    }

    const allJobs = useSelector((state) => state.jobs.jobs);
    
    useEffect(()=>{
      window.scrollTo(0, 0);
      if (!allJobs|| allJobs.length === 0) {
        setLoading(true);
        fetchAllJobs();
      }
    },[]);

//allJobs, dispatch
    // const getAllJobList = () => {
     
    //   fetch("https://serverless-api.shubhankardev8.workers.dev/v1/api/all-jobs", {
    //     method:"GET",
    //     headers:{
    //       "Content-Type":"application/json"
    //     },
    //     mode:"cors",
    //   })
    //   .then((res) => {
    //     if (res && res.ok){
    //       return res.json(); 
         
    //     }else {
    //       throw new Error("Network response was not ok.");
    //     }
    //   }).then((data) => {
    //    // console.log('inside res');
    //    // console.log('data:', data);
    //    // console.log('message:', data.message.results);
    //     setList(data.message.results); // Set the list using the correct data property
    //   })
    //   .catch(e => {
    //     console.log(e.error);
    //    // setList([...JobCardData]);
    //   })
    // }

  return (
    <React.Fragment>
    <div className='bg-slate-100 h-auto pt-10'>
      <Seo
        title="UI/UX Jobs Hub - Find UI/UX Design and Research Jobs"
        description="Browse our daily updated job board and find great UI/UX design jobs, product design jobs and UX researcher jobs at startups and large companies."
        type="WebSite"
        name="UI/UX JobsHub"
      />
      {/* <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="title" content="UI/UX JobsHub"></meta>
                <meta name="description" content="Browse our daily updated job board and find great UI/UX design jobs, product design jobs and UX researcher jobs at startups and large companies."></meta>
               <meta name="keywords" content="UI/UX design jobs, Product design jobs, UX researcher jobs, Startups, Large companies, Daily updated job board, Design job board, UI/UX job listings, UX research positions, Product designer roles, Design careers,jobs,jobBoard"></meta>
                <link rel="icon" href="/favicon.ico" />
      </Helmet> */}
        <div className='bg-slate-100  h-auto md:h-72 p-3 w-100 rounded-xl '>
            <BannerHome/>
        </div>
        {/* this is where we render the cards */}
        <div className='flex flex-col mx-3'>
        <Spin size="large" spinning={loading} fullscreen />
        {
          loading && (<><Skeleton className='w-full md:w-4/6 mx-auto' active /><Skeleton className='w-full md:w-4/6 mx-auto' active /><Skeleton className='w-full md:w-4/6 mx-auto' active /></>) 
        }
        {
        allJobs.length > 0 && allJobs.map((ele)=>(
          <Link to={`/summary?id=${ele.id}`} key={ele.id}>< AdvertiseCard props={ele}/></Link>
         ))
        }
        {
          !loading && allJobs.length === 0 && (<><Empty description={
          <Typography.Text strong>
            No jobs <a href="#API">found!!!</a>
          </Typography.Text>} className='w-full md:w-4/6 mx-auto mb-5'/></>)
        }
        </div>   
        <Footers/>
    </div>
    </React.Fragment>
  )
}

export default HomePage;